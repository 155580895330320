.works{
    padding: 1em 5.5em;
    display: flex;
    flex-direction: column;
    h1{
        color: #3b373b;
        font-size: 1.6rem;
        font-weight: 300;
        margin: 0.7em 0em;
    }
    .works-wrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 1em;
        .works-text{
            font-size: 15px;
        }

        .works-cover{
            width: 20em;
            height: 15em;
            background-color: #4150d94d;
            border-radius: 10px;
            cursor: pointer;
            box-shadow: -2px 2px 6px 2px rgba(0, 0, 0, 0.385);
            -webkit-box-shadow: -1px 1px 6px 2px rgba(0, 0, 0, 0.361);
            -moz-box-shadow: -1px 1px 6px 2px rgba(0, 0, 0, 0.33);
            .seperate{
                border-radius: 10px 10px 0px 0px;
                display: flex;
                align-items: center;
                height: 2.5em;
                background-color: rgb(159, 159, 179);
                p{
                    font-size: 0.77rem;
                    background-color: #ffffff;
                    border-radius: 15px;
                    padding: 0.3em 0.5em;
                    font-weight: 400;
                }
                a{
                    text-decoration: none;
                    color: #000
                }
            }
            .work{
                position: relative;
                width: 100%;
                height: 12.5em;
                border-radius: 0px 0px 10px 10px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                transition: .3s ease;
                p{
                    opacity: 0;
                    position: absolute;
                    color: #ffffff;
                    font-size: 0.74rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 96.4%;
                    width: 95%;
                    backdrop-filter: blur(5px);
                    background-color: #3b373b71;
                    border-radius: 0px 0px 7px 7px;
                    padding: 0.3em 0.7em;
                    transition: .3s ease;
                }
                &:hover p{
                    opacity: 1;
                }
            }
            .earnbroker{
                background-image: url('../../images/simms.png');
            }
            .cosmetic{
                background-image: url('../../images/cosmetics1.jpeg');
            }
            .creditaffairs{
                background-image: url('../../images/loan1.jpeg');
            }
            .todo{
                background-image: url('../../images/ade.png');
            }
            .calc{
                background-image: url('../../images/payzap.PNG');
            }
            .tgr{
                background-image: url('../../images/tgr.png');
            }

        }
    }
}

@media (max-width:1000px) {
    .works{
       padding: 1em;
       h1{
        font-size: 1.4rem;
       }
    }
}