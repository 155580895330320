.Navbar {
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    height: 4em;
    align-items: center;
    z-index: 10;
  
    .navbar-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1em;
      width: 100%;
  
      .side-bar {
        position: absolute;
        left: 0;
        visibility: hidden;
        top: 0;
        width: 3.88em;
        height: 0vh;
        display: flex;
        gap: 1.7em;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: height 0.3s ease;
  
        &.open {
          height: 100vh;
          transition: height 0.3s ease;
          visibility: visible;
          a{
            opacity: 1;
          }
        }
  
        a {
          display: flex;
          color: #fff;
          background-color: #3b373b;
          opacity: 0;
          transition: 0.3s ease;
          border: 1px solid rgba(255, 255, 255, 0);
          padding: 0.4em;
          border-radius: 50%;
          position: relative;
          box-shadow: -2px 3px 13px -2px rgba(0, 0, 0, 0.75);
          -webkit-box-shadow: -3px 4px 13px -2px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: -3px 4px 13px -2px rgba(0, 0, 0, 0.75);
          &:hover p{
            opacity: 1;
            width: max-content;
            padding: 0.3em 0.5em;
            z-index: 1;
          }
          &:hover{
            border: 1px solid rgb(255, 255, 255);
          }
          p{
            opacity: 0;
            position: absolute;
            width: 0em;
            margin-left: 2em;
            background-color: #fff;
            font-size: 0.8rem;
            font-weight: 300;
            color: #000;
            box-shadow: -5px 4px 8px 0px rgba(0, 0, 0, 0.244);
            -webkit-box-shadow: -5px 4px 8px 0px rgba(0, 0, 0, 0.283);
            -moz-box-shadow: -5px 4px 8px 0px rgba(0, 0, 0, 0.237);
            border-radius: 5px;
            transition: .3s ease;
          }
        }
      }
  
      .menu {
        border-radius: 50%;
        position: absolute;
        z-index: 2;
        color: #fff;
        background-color: #3b373b;
        box-shadow: -2px 3px 13px -2px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: -3px 4px 13px -2px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: -3px 4px 13px -2px rgba(0, 0, 0, 0.75);
      }
    }
  }

  @media (max-width: 700px){
    .Navbar{
      .navbar-container{
        .side-bar {
          position: fixed;
          right: 0;
          top: 96%;
          width: 0vw;
          height: 0em;
          flex-direction: row;
          transition: width 0.3s ease;
    
          &.open {
            height: 0em;
            width: 100vw;
            transition: width 0.3s ease;
            a{
              opacity: 1;
            }
          }
          a{
            p{
              margin-left: -1.5em;
              margin-top: -3em;
            }
          }
        }
      }
    }
  }
  