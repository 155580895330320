.home{
    display: flex;
    position: relative;
    height: 100vh;
    .weather-time{
        position: absolute;
        padding: 1em;
        p{
            color: #3b373b;
            font-size: 0.788rem;
        }
        .weather{
            display: flex;
            align-items: center;
            position: relative;
            span{
                position: absolute;
                margin-left: 1.5em;
                font-size: 2rem;
            }
        }
    }

    .introduction{
        margin-left: 5em;
        position: relative;
        flex: 4;
        height: 88vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 5em;
        &::before{
            content: "";
            width: 1px;
            position: absolute;
            z-index: 0;
            margin-top: -5em;
            height: 100vh;
            background-color: #3b373b;
        }

        .introduction-texts{
            z-index: 1;
            background-color: #cad4df;
            text-align: center;
            border-radius: 7px;
            padding: 1em;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            transition: 1s ease;
            gap: 1em;

            .introduction-text{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 1rem;
                font-weight: 300;
                color: #3b373b;
            }
    
            .buttons{
                display: flex;
                flex-direction: column;
    
                .socials{
                    color: #3b373b;
                    display: flex;
                    align-items: center;
                    gap: 0.5em;
                    div{
                        padding: 0.6em;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #3b373b;
                        color: #fff;
                        border-radius: 50%;
                        transition: .2s ease;
                        &:hover{
                            box-shadow: 1px 1px 10px 1px #fff;
                            padding: 0.7em;
                        }
                    }
                }
                button{
                    background-color: #3b373b;
                    border: none;
                    color: #fff;
                    border-radius: 20px;
                    width: 11em;
                    margin-bottom: 1em;
                    height: 3.6em;
                    outline: none;
                    font-weight: 200;
                    font-size: 0.7rem;
                    transition: .2s ease;
                    &:hover{
                        box-shadow: 1px 1px 10px 1px #fff;
                        height: 3.8em;
                        width: 11.2em;
                    }
                }
            }
            h1{
                text-transform: uppercase;
                color: #3b373b;
                font-size: 1rem;
                font-weight: 300;
                display: flex;
                flex-direction: column;
                gap: 0.5em;
    
                .blinking-slash {
                    display: flex;
                    align-items: center;
                    
                    &:after {
                        margin-top: -0.15em;
                      content: "|";
                      animation: blink .9s infinite;
                    }
                  }
                  
                  @keyframes blink {
                    50% {
                      opacity: 0;
                    }
                  }
    
                span{
                    display: flex;
                    align-items: center;
                    gap: 0.3rem;
                    color: #3b373b;
                    font-size: 2rem;
                    font-weight: 500;
                    z-index: 1;
                }
            }
        }
    }



    .body{
        position: relative;

        .monitor-stand{
            position: absolute;
            height: 0.5em;
            margin-bottom: -0.5em;
            width: 80%;
            bottom: 0;
            right: 0;
            background-color: #fff;
            border-radius: 7px 0px 0px 7px;
        }

  
        width: 100%;
        flex: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        .links-cover{
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .monitor-img{
                position: absolute;
                bottom: 0;
                margin-bottom: -0.7em;
                height: 15em;
            }

            .links-images{
                display: flex;
                width: 100%;
                height: 80vh;
                align-items: center;
                justify-content: center;
                position: relative;
        
                a{
                    color: #000;
                    position: absolute;
                }
        
                .click-main-screen{
                    box-shadow: 0px 0px 50px 20px #978c97a8;
                    background-image: url('../images/lmscreen.gif');
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 15.84em;
                    height: 8.9em;
                    bottom: 0;
                    z-index: 6;
                    margin-bottom: 4.72em;
                    cursor: pointer;
                    position: absolute;
                    // animation: blink 2s infinite;
                }
                @keyframes blink {
                    50% {
                        opacity: 0;
                    }
                }
                .workspop{
                    margin-top: -5em;
                    margin-right: 10em;
                    transition: .1s ease-in-out;
                }
                .links{
                    margin-top: -8em;
                    margin-right: 28em;
                    transition: .3s ease-in-out;
                }
                .school{
                    margin-top: -8em;
                    margin-right: -18em;
                    transition: .5s ease-in-out;
                }
                .contactpop{
                    margin-top: -14em;
                    margin-right: -1em;
                    transition: .2s ease-in-out;
                }
        
                .closed{
                    margin-top: 15em;
                    opacity: 0;
                    transition: .3s ease-in-out;
                }
                .imgs{
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 7px;
                    margin-left: 1em;
                    position: absolute;
                    z-index: 5;
                    &:hover p{
                        margin-top: -9.6em;
                        padding: 0.5em 1em;
                        opacity: 1;
                    }
                    p{
                        position: absolute;
                        opacity: 0;
                        text-transform: capitalize;
                        font-weight: 300;
                        margin-top: -4em;
                        width: max-content;
                        margin-left: 1em;
                        font-size: 0.9rem;
                        z-index: -1;
                        background-color: #fff;
                        border-radius: 5px;
                        padding: 0.5em 0em;
                        transition: .3s ease;
                        box-shadow: -5px 4px 8px 0px rgba(0, 0, 0, 0.244);
                        -webkit-box-shadow: -5px 4px 8px 0px rgba(0, 0, 0, 0.283);
                        -moz-box-shadow: -5px 4px 8px 0px rgba(0, 0, 0, 0.237);
                    }
                    img{
                        height: 92px;
                        transition: .3s ease-in-out;
                    }
                }
                .imgs:hover img{
                    height: 105px;
                }
            }
        }    
    }
    
}

@media (max-width: 1050px) {
    .home{
        flex-direction: column;
        .introduction{
            margin-left: 0em;
            padding: 0em 9em;
            .introduction-texts{
                margin-bottom: -1em;
            }
        }
        .body{
            display: none;
        }
    }
  }
@media (max-width: 890px) {
    .home{
        .introduction{
            padding: 0em 4em;
        }
    }
  }
@media (max-width: 600px) {
    .home{
        .introduction{
            padding: 0em 1em;
            .introduction-texts{
                margin-bottom: -1em;
                h1{
                    span{
                        font-size: 1.4rem;
                    }
                }
            }
        }
        .body{
            display: none;
        }
    }
}