.testimonials{
    padding: 1em 5.5em;
    display: flex;
    flex-direction: column;
    color: #fff;
    .slick-prev,
    .slick-next {
      display: none !important;
    }
    h1{
        color: #3b373b;
        font-size: 1.6rem;
        font-weight: 300;
    }
    .slider{
        padding: 2em 10em;

        .slides{
            height: max-content;
            background-color: #3b373b;
            height: 30em;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 7px;
            .slides-content{
                padding: 1em 2em;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                p{
                    font-size: 1.8rem;
                    font-weight: 900;
                    margin-bottom: 1em;
                }

                .photo{
                    border-radius: 50%;
                    background-color: #8041d9;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    width: 7em;
                    height: 7em;
                    img{
                        height: 11em;
                    }
                    }
                    .text{
                        font-weight: 300;
                        font-size: 0.9rem;
                        span{
                            font-weight: 600;
                        }
                    }
            }

        }

    }

}

@media (max-width:1000px) {
    .testimonials{
       padding: 1em;
       h1{
        font-size: 1.4rem;
       }
    }
}
@media (max-width:800px) {
    .testimonials{
        .slider{
            padding: 1em 5em;
            .slides{
                .slides-content{
                    padding: 1em;
                    p{
                        font-size: 1.2rem;
                    }
                    .photo{
                        width: 5em;
                        height: 5em;
                        img{
                            height: 8em;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width:550px) {
    .testimonials{
        .slider{
            padding: 1em;
        }
    }
}
@media (max-width:360px) {
    .testimonials{
        .slider{
            padding: 1em 0em;
        }
    }
}